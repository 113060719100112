.patient-picker {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.patient-picker .female {
    color: #b8446a;
}
.patient-picker .male {
    color: #3B69BE;
}
.patient-picker tr {
    cursor: pointer;
}
.patient-picker tbody tr:hover {
    background: #EEE;
}

.patient-picker .label-warning {
    background: #FFF;
    color: #f0ad4e;
    border: 1px solid #f0ad4e;
    padding: 1px 4px 2px;
}
