.flex-row {
    flex-flow  : row;
    display    : flex;
    flex-wrap  : wrap;
    align-items: stretch;
    flex       : 1 1 0px;
    justify-content: baseline;
}

/* sample-app --------------------------------------------------------------- */

.sample-app .page-title {
    margin-top: 0;
}


.sample-app .flex-cell {
    /* display: flex; */
    flex: 0 1 20em;
    /* white-space: pre; */
    align-self: flex-start;
    justify-self: flex-start;
    align-items: center;
    /* justify-content: center; */
    padding: 0 1em 0 0;
}
.flex-cell.flex-cell-2 {
    flex: 0 1 40em;
}
.flex-cell.flex-cell-full {
    flex: 0 1 100%;
}
.flex-cell > label {
    display: block;
    margin: 0;
    line-height: 1;
}
.flex-cell > label input {
    vertical-align: bottom;
}

#root > .flex-row {
    min-height : 100vh;
    background : #f8f8f8;
    padding    : 10px;
}

#root > .flex-row > .alert {
    margin       : 10px;
}

.panel {
    display      : flex;
    flex-flow    : column;
    flex         : 1 1 440px;
    margin       : 10px;
    padding      : 20px;
    overflow     : hidden;
    border-radius: 10px;
    border       : 0 none;
    box-shadow   : 0 0 1px 0 #999;
}

.panel h4 {
    margin     : -10px 0 10px 0;
    padding    : 0;
    font-size  : 16px;
    align-self : flex-start;
    color      : #0053a1;
    position   : relative;
    z-index    : 2;
    line-height: 20px;
    display    : inline-block;
    font-weight: 600;
}

.panel .panel-footer {
    margin: 0 -20px -20px;
}

pre {
    max-width    : 100%;
    overflow     : auto;
    background   : #FFF linear-gradient(#FFF, #F6F6F6);
    padding      : 14px 0px 0px;
    flex         : 1 1 200px;
    color        : #333;
    margin       : 0 2px 2px;
    box-sizing   : border-box; 
    font-family  : Menlo, monospace;
    -webkit-overflow-scrolling: touch;
    border: 0 none;
    background: transparent;
}

table.table > tbody > tr > th {
    text-align: right;
    vertical-align: top;
    width: 30% !important;
    white-space: normal !important;
    word-wrap: break-word;
    break-inside: auto;
}

td {
    width: auto;
}

.table.table-fixed {
    table-layout: fixed;
}

.table td {
    white-space: normal !important;
    word-wrap: break-word !important;
    break-inside: auto !important;
}
