body {
    font-size: 15px;
}

hr {
    margin: 1ex 0 1em;
}

.mt-0 { margin-top: 0    }
.mt-1 { margin-top: 10px }
.mt-2 { margin-top: 20px }
.mb-0 { margin-bottom: 0    }
.mb-1 { margin-bottom: 10px }
.mb-2 { margin-bottom: 20px }

.pt-0 { padding-top: 0    }
.pt-1 { padding-top: 10px }
.pt-2 { padding-top: 20px }
.pb-0 { padding-bottom: 0    }
.pb-1 { padding-bottom: 10px }
.pb-2 { padding-bottom: 20px }
.pl-0 { padding-left: 0    }
.pl-1 { padding-left: 10px }
.pl-2 { padding-left: 20px }
.pr-0 { padding-right: 0    }
.pr-1 { padding-right: 10px }
.pr-2 { padding-right: 20px }

.nav-tabs > li > * {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    position: relative;
    display: block;
    padding: 10px 15px;
    cursor: pointer;
    opacity: 0.7;
}

.nav-tabs > li.active > * {
    opacity: 1;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.tab-content {
    /* border-width: 0 1px 1px; */
    /* border-style: none solid solid; */
    /* border-color: transparent #DDD #DDD; */
    padding: 20px 0 0;
    /* border-radius: 0 0 5px 5px; */
    /* background: linear-gradient(#FFF, #F3F3F3); */
}

.btn.disabled, .btn:disabled {
    pointer-events: none;
    background: #DDD;
    border-color: #AAA;
    color: #666;
    text-shadow: 0 1px #FFF;
}

.search-match {
    background: rgba(255, 222, 0, 0.76);
    box-shadow: 0 0 1px 0 rgb(0, 0, 0, 0.5) inset;
    border-radius: 2px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}

textarea {
    resize: vertical;
}

textarea.invalid, textarea.invalid:focus {
    border-color: #C00;
}

a.disabled {
    pointer-events: none;
    filter: grayscale();
}

summary {
    display: list-item;
    cursor: pointer;
}
summary::marker {
    color: #888;
    font-size: 13px;
}

details {
    padding: 10px 0;
}

.form-control:invalid {
    border-color: #C00;
}

.btn-group {
    white-space: nowrap;
    display: inline-flex;
}
.btn-group .btn:focus {
    outline: none;
}

details + details {
    border-top: 1px dashed #CCC;
}
