.encounter-picker {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.encounter-picker tr {
    cursor: pointer;
}
.encounter-picker tbody tr:hover {
    background: #EEE;
}
